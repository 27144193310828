import { Form, Pagination, Space, Table, Typography } from "antd";
import { BaseModel, CrudOperations, PaginatedResponse } from "api";
import { AddButton } from "components/Buttons/AddButton";
import { CancelButton } from "components/Buttons/CancelButton";
import { DeleteButton } from "components/Buttons/DeleteButton";
import { EditButton } from "components/Buttons/EditButton";
import { SaveButton } from "components/Buttons/SaveButton";
import { SearchInput } from "components/SearchInput";
import { TwoBlocksRow } from "./TwoBlocksRow";
import { useEditableTable } from "hooks/useEditableTable";
import { useListFilters } from "hooks/useListFilters";
import type { TableSticky } from "rc-table/lib/interface";
import React, { useState } from "react";
import { ColumnSelector } from "../DataTable/ColumnSelector";
import { useAccountSettings } from "hooks/useAccountSettings";
import { TableRowSelection } from "antd/es/table/interface";
import { TitleWithActions } from "components/TitleWithActions";

export interface ColumnProps {
  key: string;
  [key: string]: any;
}

export interface DataTableWithEditableRowProps<TRecord> {
  crud: CrudOperations<TRecord>;
  tableKey: string;
  createTitle?: string;
  columns: ColumnProps[];
  onDelete?: (record: TRecord) => void;
  extraProps?: any;
  extraFormProps?: any;
  transformBeforeSave?: (record: TRecord) => any;
  previewEnabled?: boolean;
  initialData?: any;
  expandable?: any;
  canEdit?: boolean;
  canEditRow?: (record: TRecord) => boolean;
  canDelete?: boolean;
  highlightedRowId?: string[];
  onRowClick?: (record: TRecord) => void;
  hideSearch?: boolean;
  className?: string;
  rowSelection?: TableRowSelection<TRecord>;
  extraActions?: JSX.Element[];
  extraRowActions?: (record: TRecord) => JSX.Element[];
  sticky?: boolean | TableSticky;
  pageSize?: number;
  onData?: (data: PaginatedResponse<TRecord>) => void;
  summary?: () => any;
  title?: string;
  saveAndCreateText?: string;
  createText?: string;
}

export const DataTableWithEditableRow = <TRecord extends BaseModel>({
  crud,
  tableKey,
  createTitle,
  canEdit = true,
  canEditRow = () => true,
  canDelete = undefined,
  columns,
  extraProps = {},
  extraFormProps = {},
  transformBeforeSave = (data) => data,
  previewEnabled = false,
  initialData = {},
  rowSelection,
  expandable = {},
  highlightedRowId,
  onRowClick,
  hideSearch,
  className,
  extraActions,
  extraRowActions = () => [],
  sticky,
  pageSize = 100,
  onData,
  summary,
  title,
  saveAndCreateText = "Save and create",
  createText = "Create",
}: DataTableWithEditableRowProps<TRecord>) => {
  const {
    paginationProps,
    onSearch,
    sorter,
    search,
    onTableChange,
    listFilters,
  } = useListFilters({ tableKey, pageSize });

  const { visibleCols: preservedVisibleCols, saveVisibleCols } =
    useAccountSettings();

  const {
    data,
    isEditing,
    editItem,
    tableProps,
    formProps,
    editButtonProps,
    saveButtonProps,
    cancelButtonProps,
    deleteButtonProps,
  } = useEditableTable<TRecord>({
    crud,
    onData,
    extraProps: { ...extraProps },
    extraFormProps: extraFormProps,
    filterProps: { ...listFilters },
    transformBeforeSave,
    previewEnabled,
    // todo: add 'tableKey' to load column settings
  });

  const columnsWithActions = [
    ...columns.map(({ renderView, renderEdit, ...column }) => ({
      ...column,
      render: (value: any, record: TRecord) =>
        isEditing(record.id)
          ? renderEdit
            ? renderEdit(value, record)
            : column.render
            ? column.render(value, record)
            : value
          : renderView
          ? renderView(value, record)
          : column.render
          ? column.render(value, record)
          : value,
    })),
    ...(canEdit
      ? [
          {
            key: "action",
            width: 1,
            fixed: "right",
            render: (record: TRecord) => {
              if (!canEditRow?.(record)) return null;
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton size="small" {...saveButtonProps} />
                    <CancelButton size="small" {...cancelButtonProps} />
                    {extraRowActions(record)}
                  </Space>
                );
              }
              return (
                <Space>
                  <EditButton {...editButtonProps(record)} size="small" />
                  {canDelete !== false && (
                    <DeleteButton
                      {...deleteButtonProps(record.id)}
                      size="small"
                    />
                  )}

                  {/* <ActivityLog
              size={"small"}
              id={record.id}
              modelMeta={waybillItemMeta}
            /> */}
                  {extraRowActions(record)}
                </Space>
              );
            },
          },
        ]
      : []),
  ];

  const alwaysVisibleCols = columns
    .filter((col) => col.renderEdit)
    .map((col) => col.key);

  const [visibleCols, setVisibleCols] = useState(
    preservedVisibleCols(tableKey).length > 0
      ? preservedVisibleCols(tableKey)
      : columns.map((c) => c.key)
  );

  const columnsToDisplay = columnsWithActions.filter(({ key }) =>
    [...alwaysVisibleCols, ...visibleCols, "action"].includes(key)
  );

  const rowClassName = (record: TRecord) => {
    return highlightedRowId?.includes(String(record.id))
      ? "highlighted-row"
      : "";
  };

  const columnSelectorCols = columns
    .filter(({ renderEdit }) => !renderEdit)
    .map(({ title, key }) => ({
      title: String(title),
      key,
    }));

  const handleColumnSelectorChange = (data: any) => {
    /*
     * Do not allow change visible cols if no cols selected at all.
     */
    if (data.length === 0) return false;
    setVisibleCols(data);
    saveVisibleCols(tableKey, data);
  };
  return (
    <div className={className}>
      <TwoBlocksRow
        left={
          hideSearch || title ? (
            <Typography.Title
              level={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {title}
            </Typography.Title>
          ) : (
            <SearchInput defaultValue={search} onSearch={onSearch} />
          )
        }
        right={
          <Space>
            {extraActions?.map((action, id) => (
              <React.Fragment key={id}>{action}</React.Fragment>
            ))}
            {createTitle && canEdit && (
              <AddButton {...editButtonProps({ id: null, ...initialData })}>
                {editItem
                  ? [saveAndCreateText, createTitle].join(" ")
                  : [createText, createTitle].join(" ")}
              </AddButton>
            )}
            <ColumnSelector
              items={columnSelectorCols}
              selected={visibleCols}
              setItems={handleColumnSelectorChange}
            />
          </Space>
        }
      />
      <Form {...formProps}>
        <Table<TRecord>
          size={"small"}
          columns={columnsToDisplay as any}
          {...tableProps}
          sticky={sticky}
          bordered={false}
          onChange={onTableChange}
          pagination={false}
          summary={summary}
          expandable={expandable}
          rowSelection={rowSelection}
          scroll={{ x: "max-content" }}
          rowClassName={rowClassName}
          onRow={(record: TRecord) => ({
            onClick: () => {
              onRowClick?.(record);
            },
          })}
        />
        {data?.totalPages !== 1 && (
          <Pagination
            style={{
              margin: "12px 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
            size="small"
            {...paginationProps(data)}
          />
        )}
      </Form>
    </div>
  );
};
