import { Button, Card, Divider, Form, Input, InputNumber, Select } from "antd";
import { Proposal, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";
import {
  FormColInput,
  FormColInput2,
  MultiColumnForm,
} from "components/MultiColumnForm";
import { ProposalState } from "components/State/ProposalState";
import { renderDateTime, renderPrice } from "utils/renderer";
import { AjaxSelect } from "components/AjaxSelect";
import TextArea from "antd/es/input/TextArea";
import { LinkToTask } from "components/Links/LinkToTask";
import { useAuthContext } from "AuthProvider";
import { CommentButton } from "components/CommentComponents";
import { renderTextWithLinks } from "pages/Auction/AuctionEditPage";

export const MyProposalEditPage = () => {
  const { hasAdminRole, hasUserRole, user } = useAuthContext();
  const { proposalId: id } = useParams<{ proposalId: string }>();
  const {
    data: respData,
    showForm,
    isEditMode,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Proposal>({
    id,
    isEdit: !id,
    endpoint: api.myProposal,
    onAfterCreateNavigateTo: (data) => `/platform/my-proposals/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/my-proposals",
  });

  const data = id
    ? respData
    : ({
        currency: "USD",
        shooting_country_id: user?.shooting_country_id,
      } as any);

  const showField = (field: string) =>
    isEditMode ? true : (data as any)[field];

  const handleSubmit = () => {
    const fd = new FormData();
    fd.append("state", "submitted");
    save(fd);
  };

  const handleSubmitPriceChanged = () => {
    const fd = new FormData();
    fd.append("state", "good_idea");
    save(fd);
  };

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? "Proposal" : "Create Proposal"}
          actions={
            <>
              {data?.state !== "draft" && ProposalState.render(data?.state)}
              {(!id ||
                ["draft", "changes_requested", "price_negotiation"].includes(
                  data?.state
                )) &&
                actions}
              {/* {id && hasAdminRole && (
                <ProposalState.Selector id={id} state={data?.state} />
              )} */}
              {id && (
                <CommentButton
                  key="CommentButton"
                  modelName="Proposal"
                  modelId={id}
                />
              )}
              {id &&
                hasUserRole &&
                ["draft", "changes_requested"].includes(data?.state) && (
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={isEditMode}
                  >
                    Submit
                  </Button>
                )}
              {id &&
                hasUserRole &&
                ["price_negotiation"].includes(data?.state) && (
                  <Button
                    type="primary"
                    onClick={handleSubmitPriceChanged}
                    disabled={isEditMode}
                  >
                    Submit
                  </Button>
                )}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            labelWrap={true}
            style={{ maxWidth: 1200 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Idea/Video topic"
              name={field("name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.name,
                <Input />,
                [undefined, "draft", "changes_requested"].includes(data?.state)
              )}
            </FormColInput>
            <FormColInput
              label="Type"
              rules={[{ required: true }]}
              name={field("type")}
            >
              {viewOrEdit(
                renderProposalType(data.type),
                <Select
                  options={[
                    { value: "idea", label: "Idea" },
                    { value: "video", label: "Video" },
                  ]}
                />,
                [undefined, "draft", "changes_requested"].includes(data?.state)
              )}
            </FormColInput>
            <FormColInput
              label="Shooting country"
              rules={[{ required: true }]}
              name={field("shooting_country_id")}
            >
              {viewOrEdit(
                data?.shooting_country?.name,
                <AjaxSelect endpoint={api.country} />,
                [undefined, "draft", "changes_requested"].includes(data?.state)
              )}
            </FormColInput>
            {showField("quantity") && (
              <FormColInput label="Video quantity" name={field("quantity")}>
                {viewOrEdit(data.quantity, <InputNumber min={0} />)}
              </FormColInput>
            )}
            <FormColInput2
              label="Description"
              name={field("description")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(renderTextWithLinks(data?.description), <TextArea />)}
            </FormColInput2>
            <FormColInput
              label="Proposed budget total"
              name={field("proposed_budget")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.proposed_budget && renderPrice(data.proposed_budget),
                <Input />
              )}
            </FormColInput>
            {showField("currency") && (
              <FormColInput label="Currency" name={field("currency")}>
                {viewOrEdit(
                  data.currency,
                  <AjaxSelect
                    endpoint={api.currency}
                    fields={(unit) => ({ value: unit.code, label: unit.code })}
                    doGet={false}
                  />
                )}
              </FormColInput>
            )}
            {showField("proposed_work_duration") && (
              <FormColInput
                label="Proposed work duration"
                name={field("proposed_work_duration")}
              >
                {viewOrEdit(
                  data.proposed_work_duration &&
                    data.proposed_work_duration + " Day(-s)",
                  <InputNumber min={0} suffix="Days" />
                )}
              </FormColInput>
            )}
            {data?.submitted_at && (
              <FormColInput label="Submitted at">
                {renderDateTime(data.submitted_at)}
              </FormColInput>
            )}
            {id && showField("admin_comment") && (
              <FormColInput
                label="CP Comment"
                name={hasAdminRole && field("admin_comment")}
              >
                {viewOrEdit(
                  data.admin_comment,
                  hasAdminRole ? <Input /> : data.admin_comment
                )}
              </FormColInput>
            )}
            {id && data?.task && (
              <>
                <Divider>Task details</Divider>
                <FormColInput label="Task" name={field("task")}>
                  <LinkToTask {...data.task} />
                </FormColInput>
                <FormColInput
                  label="Approved by"
                  name={field("approved_by_user")}
                >
                  {data?.approved_by_user?.name}
                </FormColInput>
              </>
            )}
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};

export const renderProposalType = (type: string) => {
  const label = [
    { value: "idea", label: "Idea" },
    { value: "video", label: "Ready video" },
  ].find((t) => t.value === type)?.label;
  return label || type;
};
