import { Card } from "antd";
import { Role, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";

export const RoleListPage = () => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/platform/roles/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Roles"
        actions={
          <ButtonCreateNavigate link="/platform/roles/create">
            Role
          </ButtonCreateNavigate>
        }
      />
      <DataTable<Role>
        endpoint={api.role}
        columns={columns}
        tableId={"RoleListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
];
