import { Card } from "antd";
import { Payment, api } from "api";
import { useAuthContext } from "AuthProvider";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { PaymentState } from "components/State/PaymentState";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";
import { renderPrice } from "utils/renderer";

export const PaymentListPage = () => {
  const navigate = useNavigate();
  const { hasAccess } = useAuthContext();

  const handleEdit = (id: string) => {
    navigate(`/platform/payments/${id}`);
  };

  const hasEditPermission = hasAccess(api.payment.key + "_edit");

  return (
    <Card>
      <TitleWithActions
        title="Payments"
        actions={
          hasEditPermission && (
            <ButtonCreateNavigate link="/platform/payments/create">
              Payment
            </ButtonCreateNavigate>
          )
        }
      />
      <DataTable<Payment>
        endpoint={api.payment}
        columns={columns as any}
        tableId={"PaymentListPage"}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Contract",
    dataIndex: "contract",
    key: "contract",
    sortIndex: "contract.id",
    sorter: true,
    render: (contract: any) => "CONTRACT-" + contract?.id,
  },
  {
    title: "User",
    dataIndex: ["user", "name"],
    sortIndex: "user.name",
    key: "user_name",
    sorter: true,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (value: any) => renderPrice(value),
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    sorter: true,
  },
  {
    title: "Payment date",
    dataIndex: "payment_date",
    key: "payment_date",
    sorter: true,
  },
  {
    title: "Payment description",
    dataIndex: "payment_description",
    key: "payment_description",
    sorter: true,
  },
  {
    title: "Payment details",
    dataIndex: "payment_details",
    key: "payment_details",
    sorter: true,
  },
  {
    title: "Payment method",
    dataIndex: "payment_method",
    key: "payment_method",
    sorter: true,
  },
  {
    title: "Payment type",
    dataIndex: "payment_type",
    key: "payment_type",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    sorter: true,
    render: PaymentState.render,
  },
];
