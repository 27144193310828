import {
  DashboardOutlined,
  GroupOutlined,
  TeamOutlined,
  BankOutlined,
  CodeSandboxOutlined,
  DollarOutlined,
  UserOutlined,
  FireOutlined,
  ProfileOutlined,
  AuditOutlined,
  ToolOutlined,
  CarOutlined,
  AreaChartOutlined,
  FileTextOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AuctionHammerIcon } from "components/Icons/AuctionHammerIcon";
import { Menu, MenuProps } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "AuthProvider";
import { api } from "api";

export interface MenuItem {
  label: React.ReactNode;
  key: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: "group";
  url?: string;
  title: string;
}

const getItems = (
  hasAccess: (permission: string) => boolean,
  hasAdminRole: boolean
) => {
  function getItem(
    label: string,
    key: string | { key: string; permission: string },
    url?: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem | null {
    const keyValue = typeof key === "string" ? key : key.key;
    const permissionValue = typeof key === "string" ? key : key.permission;
    if (!hasAccess(permissionValue)) return null;
    if (!url && children?.length === 0) return null;
    return {
      key: keyValue,
      icon,
      children,
      url: url,
      label: url ? <Link to={url}>{label}</Link> : label,
      title: label,
      type,
    };
  }

  const navConfigs = [
    hasAdminRole &&
      getItem("Dashboard", "platform", "/platform", <DashboardOutlined />),

    getItem(
      "Current Auctions",
      { key: "current_auctions", permission: api.myAuction.key + "_view" },
      "/platform/current-auctions",
      <AuctionHammerIcon />
    ),
    getItem(
      "Closed Auctions",
      { key: "closed_auctions", permission: api.myAuction.key + "_view" },
      "/platform/closed-auctions",
      <AuctionHammerIcon />
    ),
    getItem(
      "My Proposals",
      api.myProposal.key + "_view",
      "/platform/my-proposals",
      <FireOutlined />
    ),
    getItem(
      "Tasks",
      api.task.key + "_view",
      "/platform/tasks",
      <ProfileOutlined />
    ),
    getItem(
      "Contracts",
      api.contract.key + "_view",
      "/platform/contracts",
      <AuditOutlined />
    ),
    getItem(
      "Payments",
      api.payment.key + "_view",
      "/platform/payments",
      <DollarOutlined />
    ),
    getItem(
      "Proposals",
      api.proposal.key + "_view",
      "/platform/proposals",
      <FireOutlined />
    ),
    getItem(
      "Auctions",
      api.auction.key + "_view",
      "/platform/auctions",
      <AuctionHammerIcon />
    ),
    getItem(
      "Users",
      { key: "UserListPage", permission: api.user.key + "_view" },
      "/platform/users",
      <TeamOutlined />
    ),
    getItem(
      "Pages",
      api.page.key + "_view",
      "/platform/pages",
      <FileTextOutlined />
    ),
    getItem(
      "Configuration",
      "configuration",
      undefined,
      <SettingOutlined />,
      [
        getItem(
          "Currencies",
          api.currency.key + "_view",
          "/platform/currencies"
        ),
        getItem(
          "Categories",
          api.category.key + "_view",
          "/platform/categories"
        ),
        getItem(
          "Subcategories",
          api.subcategory.key + "_view",
          "/platform/subcategories"
        ),
        getItem("Skills", api.skill.key + "_view", "/platform/skills"),
        getItem("Countries", api.country.key + "_view", "/platform/countries"),
        getItem("Roles", api.role.key + "_view", "/platform/roles"),
        getItem("Permissions", api.page.key + "_edit", "/platform/permissions"),
      ].filter((item) => !!item) as MenuItem[]
    ),
  ];

  const res = navConfigs.filter((item) => item !== null) as MenuItem[];
  return res;
};

export const LeftNavigation: React.FC = () => {
  const location = useLocation();
  const { hasAccess, hasAdminRole } = useAuthContext();

  const getSelectedKeys = (menuItem: MenuItem[]): string[] => {
    const items = menuItem;
    const keys = items
      .filter((item) => item.url === location.pathname)
      .map((item) => item.key);
    const childKeys = items
      .filter((item) => item.children)
      .map((item) => getSelectedKeys(item.children!))
      .reduce((result, keys) => [...result, ...keys], []);
    return [...keys, ...childKeys];
  };

  const items = getItems(hasAccess, hasAdminRole);
  const selectedKeys = getSelectedKeys(items);

  return (
    <Menu
      style={{ position: "sticky", top: 0, height: "100vh", overflowY: "auto" }}
      selectedKeys={selectedKeys}
      theme="light"
      mode="inline"
      items={items as any}
    />
  );
};

// mode="inline"
//           defaultSelectedKeys={["1"]}
//           defaultOpenKeys={["sub1"]}
//           style={{ height: "100%", borderRight: 0 }}
//           items={leftMenuItems}
