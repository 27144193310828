import { api, Permission, Role, SystemPermission } from "api";
import { App, Card, Table } from "antd";
import { TitleWithActions } from "components/TitleWithActions";
import { Crud } from "hooks/useCrud";
import { useQueryClient } from "react-query";

export const SystemPermissionPage = () => {
  const { data: permissionsData, isFetched: isFetched1 } =
    Crud.systemPermission.useList({
      extraParams: {
        perPage: 100000,
      },
    });

  const { data: rolesData, isFetched: isFetched2 } = Crud.role.useList({
    extraParams: {
      perPage: 100000,
    },
  });

  const { data: roleSystemPermissionsData, isFetched: isFetched3 } =
    Crud.roleSystemPermission.useList({
      extraParams: {
        perPage: 100000,
      },
    });

  return (
    <Card>
      <TitleWithActions title="Permissions" />
      {isFetched1 && isFetched2 && isFetched3 && (
        <SystemPermissionTable
          systemPermissions={permissionsData?.items || []}
          roles={rolesData?.items || []}
          roleSystemPermissions={roleSystemPermissionsData?.items || []}
        />
      )}
    </Card>
  );
};

const SystemPermissionTable = ({
  systemPermissions,
  roles,
  roleSystemPermissions,
}: {
  systemPermissions: SystemPermission[];
  roles: Role[];
  roleSystemPermissions: any[];
}) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const grantPermission = (role_id: any, system_permission_id: any) => {
    api.roleSystemPermission
      .create({
        role_id,
        system_permission_id,
      })
      .then((data: any) => {
        queryClient.invalidateQueries(api.roleSystemPermission.key);
        message.success(data?.message);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  const revokePermission = (role_system_permission_id: any) => {
    api.roleSystemPermission
      .delete(role_system_permission_id)
      .then((data: any) => {
        queryClient.invalidateQueries(api.roleSystemPermission.key);
        message.success(data?.message);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    ...roles.map((role) => {
      return {
        title: role.name,
        dataIndex: role.id,
        key: role.id,
        width: 100,
        render: (value: PermissionConfig, record: any) => {
          return value.system_permission_id ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => revokePermission(value.role_system_permission_id)}
            >
              ✅
            </span>
          ) : (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => grantPermission(value.role_id, record.key)}
            >
              ❌
            </span>
          );
        },
      };
    }),
  ];

  const dataSource = systemPermissions.map((sp) => {
    const row: any = {
      key: sp.id,
      code: sp.code,
      name: sp.name,
    };
    roles.forEach((role) => {
      const roleSystemPermission = roleSystemPermissions.find(
        (rsp) => rsp.system_permission_id === sp.id && rsp.role_id === role.id
      );
      row[role.id] = {
        role_id: role.id,
        system_permission_id: roleSystemPermission?.id,
        role_system_permission_id: roleSystemPermission?.id,
      };
    });
    return row;
  });

  return (
    <>
      <Table<Permission>
        sticky={{
          offsetHeader: 86,
        }}
        dataSource={dataSource}
        size="small"
        columns={columns as any}
        pagination={false}
        components={
          {
            // header: {
            //   wrapper: (props: any) => {
            //     console.log("!!! props", props);
            //     return (
            //       <th style={{ fontSize: "5p important" }}>{props.children}</th>
            //     );
            //   },
            // },
          }
        }
        scroll={{ x: 1300 }}
      />
    </>
  );
};

interface PermissionConfig {
  role_id: number;
  system_permission_id: number;
  role_system_permission_id: number;
}
