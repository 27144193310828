import { App, Button, Card, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ResponseBase, User, api } from "api";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { UserTabAvatar } from "pages/Profile/UserTabAvatar";
import { useParams } from "react-router-dom";
import { UserTabSkill } from "./UserTabSkill";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { PhoneInputEdit, PhoneInputView } from "components/CustomPhoneInput";
import { AjaxSelect } from "components/AjaxSelect";
import { UserTabRoles } from "./UserTabRoles";

export const UserEditPage = () => {
  const { userId: id } = useParams<{ userId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<User>({
    id,
    isEdit: !id,
    endpoint: api.user,
    onAfterCreateNavigateTo: (data) => `/platform/users/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/users",
  });

  const data = respData || {};

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? <>User</> : "Create user"}
          actions={
            <>
              <ResetPasswordButton userId={id} key={Date.now()} />
              {actions}
              {/* <ActivityLog id={id} modelMeta={bankAccountMeta} />
              <DeleteConfirmButton
                id={id}
                crud={api.carWork}
                onSuccessNavigateTo="/car-works"
              /> */}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="First Name"
              name={field("first_name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.first_name, <Input />)}
            </FormColInput>
            <FormColInput
              label="Last Name"
              name={field("last_name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.last_name, <Input />)}
            </FormColInput>
            <FormColInput
              label="Email"
              name={field("email")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.email, <Input />)}
            </FormColInput>

            <FormColInput label="Phone number" name={field("phone")}>
              {viewOrEdit(
                <PhoneInputView value={data?.phone} />,
                <PhoneInputEdit />
              )}
            </FormColInput>

            <FormColInput label="Country" name={field("country_id")}>
              {viewOrEdit(
                data?.country?.name,
                <AjaxSelect
                  endpoint={api.country}
                  fields={(entity) => ({
                    value: entity.id,
                    label: entity?.name,
                    code: entity.code,
                  })}
                  optionRender={(country: any) => {
                    return (
                      <>
                        <img
                          src={`https://flagcdn.com/16x12/${String(
                            country?.data?.code
                          ).toLowerCase()}.png`}
                          alt={String(country?.label)}
                          style={{ marginRight: 8 }}
                        />
                        {country?.label}
                      </>
                    );
                  }}
                />
              )}
            </FormColInput>

            <FormColInput
              label="Bio"
              name={field("bio")}
              rules={[{ required: false }]}
            >
              {viewOrEdit(data.bio, <TextArea />)}
            </FormColInput>
            <FormColInput
              label="Status"
              name={field("state")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(
                data.state,
                <Select
                  options={[
                    { label: "Inactive", value: "inactive" },
                    { label: "Active", value: "active" },
                  ]}
                />
              )}
            </FormColInput>
          </MultiColumnForm>
        )}
        {id && <UserTabAvatar id={id} isMe={false} />}
        {id && <UserTabSkill id={id} isMe={false} />}
        {id && <UserTabRoles id={id} />}
      </Card>
    </>
  );
};

export const ResetPasswordButton = ({ userId }: { userId?: string }) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { message } = App.useApp();

  const resetPasswordMutation = useMutation<ResponseBase<any>, any, string>(
    (userId) => api.user.resetPassword(userId),
    {
      onError: (e) => {
        message.error(e.response.data.message);
      },
      onSuccess: (response, id) => {
        message.success(response.message); // todo: make it dynamic based on response
        setOpen(false);
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    }
  );

  const handleResetPassword = () => {
    setIsSubmitting(true);
    resetPasswordMutation.mutate(userId!);
  };

  if (!userId) {
    return null;
  }
  return (
    <>
      <Button onClick={() => setOpen(true)}>Reset password</Button>
      <Modal
        open={open}
        title="Reset password"
        onCancel={() => setOpen(false)}
        okText="Reset"
        okButtonProps={{ loading: isSubmitting }}
        onOk={() => handleResetPassword()}
      >
        <p>Are you sure that you want to reset password?</p>
      </Modal>
    </>
  );
};
