import { Filter, FilterOption } from "api";
import { useState } from "react";

export type UseSmartFilters = ReturnType<typeof useSmartFilters>;

export interface ConfiguredSmartFilter {
  key?: string;
  wasActivated: boolean;
  filter?: Filter;
  operator?: string;
  operand?: FilterOption; // FilterOption[]
}

export const useSmartFilters = (modelName: string) => {
  const [appliedFilters, setAppliedFilters] = useState<ConfiguredSmartFilter[]>(
    []
  );

  console.log("appliedFilters", appliedFilters);

  const applyFilter = (configuredFilter: ConfiguredSmartFilter) => {
    setAppliedFilters((prev) => {
      const existingFilterIndex = prev.findIndex(
        (filter) => filter.key === configuredFilter.key
      );
      if (existingFilterIndex !== -1) {
        prev[existingFilterIndex] = configuredFilter;
        return [...prev];
      } else {
        return [...prev, configuredFilter];
      }
    });
  };

  const removeFilter = (field: string | undefined) => {
    setAppliedFilters((prev) => prev.filter((f) => f.filter?.field !== field));
  };

  const apiFilters = appliedFilters.reduce((ac, af) => {
    if (!af.filter || !af.operand || !af.operator) return ac;
    let fieldName = af.filter.field;
    if (af.operator !== "=") {
      fieldName += `:${af.operator}`;
    }
    const value = af.operand.id;
    return {
      ...ac,
      [fieldName]: value,
    };
  }, {});

  return {
    modelName,
    applyFilter,
    removeFilter,
    appliedFilters,
    apiFilters,
  };
};
