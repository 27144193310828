import { Select } from "antd";
import { StateSteps } from "./StateSteps";
import {
  StateSelectorCommon,
  StateSelectorCommonProps,
} from "./StateSelectorCommon";
import { NoWrap } from "utils/renderer";
import { UseMutateAsyncFunction } from "react-query";
import { ResponseBase } from "api";

export interface StatusOption {
  value: string | string[];
  label: string;
  icon: React.ReactElement;
  description?: string;
  color?: string;
  conditional?: boolean;
  availableActions?: string[];
  beforeChange?: (props: BeforeStateChange) => void;
}

export interface BeforeStateChange {
  title: string;
  state: string;
  sendData: UseMutateAsyncFunction<ResponseBase<any>, any, FormData, unknown>;
}

export interface Props {
  options: StatusOption[];
  endpoint: any;
  invalidateKeys?: string[];
}

type WrappedStatusSelectorProps = Pick<
  StateSelectorCommonProps,
  "id" | "state"
> & {
  onChange?: any;
};

export const GenerateStatuses = ({
  options: initialOptions,
  endpoint,
  invalidateKeys,
}: Props) => {
  const options = initialOptions.map((option, index) => {
    if (option.availableActions) return { ...option };
    const prev = initialOptions[index - 1];
    const next = initialOptions[index + 1];
    const availableActions: string[] = [];
    if (prev) {
      availableActions.push(getOptionValue(prev.value));
    }
    availableActions.push(getOptionValue(option.value));
    if (next) {
      availableActions.push(getOptionValue(next.value));
    }
    return { ...option, availableActions };
  });

  const selectOptions = options.map((option) => ({
    value: option.value,
    label: (
      <NoWrap
        value={
          <>
            {option.icon}
            {option.label}
          </>
        }
      />
    ),
    beforeChange: option.beforeChange,
  }));

  const stepsComponent = options
    .filter((option) => !option.conditional)
    .map((option) => ({
      title: option.label,
      key: option.value,
      icon: option.icon,
      description: option.description,
    }));

  const render = (value: string) => {
    const option = selectOptions.find((opt) => {
      if (Array.isArray(opt.value)) {
        return opt.value.includes(value);
      }
      return opt.value === value;
    });
    return option ? option.label : value;
  };

  const FormSelector = (props: { value?: string; onChange?: any }) => (
    <Select options={selectOptions} {...props} />
  );

  const Selector = (props: WrappedStatusSelectorProps) => (
    <StateSelectorCommon
      id={props.id!}
      endpoint={endpoint}
      invalidateKeys={invalidateKeys}
      options={selectOptions}
      dataOptions={options}
      state={props?.state}
    />
  );

  const Steps = (props: { state?: string }) => (
    <StateSteps state={props?.state} items={stepsComponent} />
  );

  return {
    model: endpoint.key as string,
    selectOptions,
    options,
    Steps,
    Selector,
    FormSelector,
    render,
  };
};

export const getOptionValue = (value: string | string[]) =>
  Array.isArray(value) ? value[0] : value;
