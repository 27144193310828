import { Card, Input } from "antd";
import { Role, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useParams } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";

export const RoleEditPage = () => {
  const { roleId: id } = useParams<{ roleId: string }>();

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<Role>({
    id,
    isEdit: !id,
    endpoint: api.role,
    onAfterCreateNavigateTo: (data) => `/platform/roles/${data.id}`,
    onAfterDeleteNavigateTo: (data) => "/platform/roles",
  });

  const data = respData || {};

  return (
    <>
      <Breadcrumbs
        items={[
          {
            title: "Roles",
            to: "/platform/roles",
          },
          {
            title: "Role",
          },
        ]}
      />
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={id ? data.name : "Create role"}
          actions={<>{actions}</>}
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="Code"
              name={field("code")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.code, <Input />)}
            </FormColInput>
            <FormColInput
              label="Name"
              name={field("name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.name, <Input />)}
            </FormColInput>
          </MultiColumnForm>
        )}
      </Card>
    </>
  );
};
