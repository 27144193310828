import { Input, Form, Divider } from "antd";
import { api, UserSkill } from "api";
import { AjaxSelect } from "components/AjaxSelect";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableWithEditableRow } from "components/DataTableWithEditableRow/DataTableWithEditableRow";
import { TitleWithActions } from "components/TitleWithActions";

export interface Props {
  id: string;
  isMe: boolean;
}

export const UserTabSkill = ({ id, isMe }: Props) => {
  return (
    <>
      <Divider />
      <DataTableWithEditableRow<UserSkill>
        title={"Skills"}
        createText="Assign"
        saveAndCreateText="Save and assign"
        crud={isMe ? api.mySkill : api.userSkill}
        extraProps={{ user_id: id }}
        createTitle="skill"
        columns={[
          {
            title: "Skill",
            dataIndex: ["skill", "name"],
            key: "skill_name",
            sorter: true,
            renderEdit: () => (
              <Form.Item name="skill_id">
                <AjaxSelect endpoint={api.skill} />
              </Form.Item>
            ),
          },
        ]}
        tableKey={"userTabSkill"}
      />
    </>
  );
};
