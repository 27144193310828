import { useAuthContext } from "AuthProvider";
import { Card } from "antd";
import { Auction, api } from "api";
import { ButtonCreateNavigate } from "components/ButtonCreateNavigate";
import { DataTable } from "components/DataTable/DataTable";
import { LinkToAuction } from "components/Links/LinkToAuction";
import { AuctionState } from "components/State/AuctionState";
import { TitleWithActions } from "components/TitleWithActions";
import { useNavigate } from "react-router-dom";
import { renderDate, renderDateTime, renderPrice } from "utils/renderer";

export interface AuctionListPageProps {
  title?: string;
  pageFilters?: any;
  type: "closed" | "current" | "all";
  endpoint: any;
}

export const AuctionListPage = ({
  title,
  pageFilters,
  type,
  endpoint,
}: AuctionListPageProps) => {
  const { hasAdminRole } = useAuthContext();
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    const path = type === "all" ? `auctions` : `my-auctions`;
    navigate(`/platform/${path}/${id}`);
  };

  const actions = hasAdminRole ? (
    <ButtonCreateNavigate link="/platform/Auctions/create">
      Auction
    </ButtonCreateNavigate>
  ) : null;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (_: any, record: Auction) => (
        <LinkToAuction id={record.id} name={record?.name} />
      ),
    },
    ...(hasAdminRole
      ? [
          {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            sorter: true,
            render: renderDateTime,
          },
          {
            title: "Start date",
            dataIndex: "start_date",
            key: "start_date",
            sorter: true,
            render: renderDateTime,
          },
          {
            title: "End date",
            dataIndex: "end_date",
            key: "end_date",
            sorter: true,
            render: renderDateTime,
          },
        ]
      : []),
    {
      title: "Commitment deadline",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: true,
      render: renderDate,
    },
    ...(hasAdminRole
      ? [
          {
            title: "Status",
            dataIndex: "state",
            key: "state",
            sorter: true,
            render: AuctionState.render,
          },
        ]
      : []),
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (type: string) => (type === "english" ? "English" : "Dutch"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: true,
      render: (value: number) => value && renderPrice(value),
    },
    {
      title: "Min Bid",
      dataIndex: "min_bid",
      key: "min_bid",
      sorter: true,
      render: (value: number) => value && renderPrice(value),
    },
    {
      title: "Max Bid",
      dataIndex: "max_bid",
      key: "max_bid",
      sorter: true,
      render: (value: number) => value && renderPrice(value),
    },
    ...(type === "closed"
      ? [
          {
            title: "Winner bid",
            dataIndex: ["winner_bid", "price"],
            key: "winner_bid",
            sorter: true,
            render: (value: number) => value && renderPrice(value),
          },
        ]
      : []),
    {
      // currency
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: true,
    },
    {
      title: "Category",
      dataIndex: "section",
      key: "section",
      sorter: true,
      render: (section: any) => section?.name,
    },
    {
      title: "Participants",
      dataIndex: "participants_count",
      key: "participants_count",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
    },
  ];

  return (
    <Card>
      <TitleWithActions title={title ?? "Auctions"} actions={actions} />
      <DataTable<Auction>
        endpoint={endpoint}
        columns={columns}
        defaultVisibleCols={
          type === "closed"
            ? [
                "name",
                "deadline_date",
                "winner_bid",
                "currency",
                "section",
                "description",
              ]
            : undefined
        }
        extraFilters={pageFilters}
        tableId={"AuctionListPage" + type}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
    </Card>
  );
};
