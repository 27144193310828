import { Button, Card, Form, Input } from "antd";
import { User, api } from "api";
import { TitleWithActions } from "components/TitleWithActions";
import { useLPForm } from "hooks/useLPForm";
import { useAuthContext } from "AuthProvider";
import { UpdatePasswordModal } from "./UpdatePassword";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { UserTabAvatar } from "./UserTabAvatar";
import TextArea from "antd/es/input/TextArea";
import { FormColInput, MultiColumnForm } from "components/MultiColumnForm";
import { UserTabSkill } from "pages/User/UserTabSkill";
import { PhoneInputEdit, PhoneInputView } from "components/CustomPhoneInput";
import { AjaxSelect } from "components/AjaxSelect";

export const ProfileEditPage = () => {
  const { invalidateQueries } = useQueryClient();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const id = user?.id;

  const {
    data: respData,
    showForm,
    form,
    save,
    field,
    viewOrEdit,
    actions,
    toFormData,
  } = useLPForm<User>({
    id,
    endpoint: api.me,
    allowedActions: () => ["edit"],
    onAfterSave(data) {
      invalidateQueries([api.me.key]);
      invalidateQueries([api.user.key]);
    },
  });

  const data = respData || {};

  if (!data) {
    return null;
  }

  if (!user || !user.id) {
    navigate("/login");
  }

  return (
    <>
      <Card style={{ border: "none", backgroundColor: "white" }}>
        <TitleWithActions
          title={"Profile"}
          actions={
            <>
              {actions}
              <Button size="middle" onClick={() => setOpen(true)}>
                Update password
              </Button>
              {/* <ActivityLog id={id} modelMeta={bankAccountMeta} />
              <DeleteConfirmButton
                id={id}
                crud={api.carWork}
                onSuccessNavigateTo="/car-works"
              /> */}
            </>
          }
        />
        {showForm && (
          <MultiColumnForm
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            style={{ maxWidth: 852 }}
            initialValues={data}
            form={form}
            onFinish={(data) => {
              save(toFormData(data));
            }}
            autoComplete="off"
          >
            <FormColInput
              label="First Name"
              name={field("first_name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.first_name, <Input />)}
            </FormColInput>
            <FormColInput
              label="Last Name"
              name={field("last_name")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.last_name, <Input />)}
            </FormColInput>

            <FormColInput
              label="Email"
              name={field("email")}
              rules={[{ required: true }]}
            >
              {viewOrEdit(data.email, <Input />)}
            </FormColInput>

            <FormColInput label="Phone number" name={field("phone")}>
              {viewOrEdit(
                <PhoneInputView value={data?.phone} />,
                <PhoneInputEdit />
              )}
            </FormColInput>

            <FormColInput label="Country" name={field("country_id")}>
              {viewOrEdit(
                data?.country?.name,
                <AjaxSelect endpoint={api.country} />
              )}
            </FormColInput>

            <FormColInput
              label="Shooting country"
              name={field("shooting_country_id")}
            >
              {viewOrEdit(
                data?.shooting_country?.name,
                <AjaxSelect endpoint={api.country} />
              )}
            </FormColInput>

            <FormColInput
              label="Bio"
              name={field("bio")}
              rules={[{ required: false }]}
            >
              {viewOrEdit(data.bio, <TextArea />)}
            </FormColInput>
          </MultiColumnForm>
        )}
        {id && <UserTabAvatar id={id} isMe={true} />}
        {id && <UserTabSkill id={id} isMe={true} />}
      </Card>
      <UpdatePasswordModal
        id={id!}
        open={open}
        key={Date.now()}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
