import { Button, Card } from "antd";
import { Role, User, api } from "api";
import { PlusOutlined } from "@ant-design/icons";
import { DataTable } from "components/DataTable/DataTable";
import { TitleWithActions } from "components/TitleWithActions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { renderDateTime } from "utils/renderer";
import { InviteAdminModal } from "../User/InviteAdminModal";

export const AdminListPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleEdit = (id: string) => {
    navigate(`/platform/admins/${id}`);
  };

  return (
    <Card>
      <TitleWithActions
        title="Admins"
        actions={
          <Button
            onClick={() => {
              setOpen(true);
            }}
            icon={<PlusOutlined />}
          >
            Admin
          </Button>
        }
        // actions={
        // <ButtonCreateNavigate link="/platform/users/create">
        //   Users
        // </ButtonCreateNavigate>
        // }
      />
      <DataTable<User>
        endpoint={api.user}
        columns={columns}
        tableId={"AdminListPage"}
        // extraFilters={{ "roles>code": "admin" }}
        onEdit={(record) => {
          handleEdit(record.id);
        }}
      />
      <InviteAdminModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Card>
  );
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Email verified at",
    dataIndex: "email_verified_at",
    key: "email_verified_at",
    sorter: true,
    render: renderDateTime,
  },
  {
    title: "Roles",
    dataIndex: "roles",
    key: "roles",
    render: (roles: Role[]) => roles.map((role) => role.name).join(", "),
  },
  {
    title: "Status",
    dataIndex: "state",
    key: "state",
    sorter: true,
  },
];
