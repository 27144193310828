import { useAuthContext } from "AuthProvider";
import { Divider, Form } from "antd";
import { api } from "api";
import { AjaxSelect } from "components/AjaxSelect";
import { DataTableWithEditableRow } from "components/DataTableWithEditableRow/DataTableWithEditableRow";
import { TitleWithActions } from "components/TitleWithActions";

export interface Props {
  id?: string;
}

export const UserTabRoles: React.FC<Props> = ({ id }) => {
  const { hasAccess } = useAuthContext();
  const columns = [
    {
      title: "Role",
      dataIndex: ["role", "name"],
      key: "role",
      sorter: true,
      renderEdit: () => (
        <Form.Item name="role_id">
          <AjaxSelect endpoint={api.role} />
        </Form.Item>
      ),
    },
  ];

  const canManageRoles = hasAccess(api.userRole.key + "_edit");

  return (
    <>
      <Divider />
      <DataTableWithEditableRow
        title={"Roles"}
        createText="Assign"
        saveAndCreateText="Save and assign"
        columns={columns}
        crud={api.userRole}
        canEdit={canManageRoles}
        canDelete={canManageRoles}
        createTitle="role"
        tableKey={"userTabRoles"}
        extraProps={{ user_id: id }}
        extraFormProps={{
          user_id: id,
        }}
      />
    </>
  );
};
